import React from 'react';
import Layout from '../../components/layout';

const Mitgliedschaften = ({ data, location, ...props }) => {
    return (
        <Layout
            location={location}
            title="Mitgliedschaften"
            noSlider
            {...props}
        >
            <p>
                Als anerkannte Fachpraxis sind wir Mitglieder in verschiedenen
                Fachgesellschaften. Die Mitgliedschaften stehen nur Ärzten mit
                spezieller und nachgewiesener Qualifikation offen. Die Aufnahme
                erfordert deshalb Bürgen und Qualifikationsnachweise.
            </p>
            <p>
                <strong>
                    Wir sind Mitglied in folgenden Fachgesellschaften:
                </strong>
            </p>
            <ul>
                <li>Berufsverband deutscher Dermatologen</li>
                <li>Deutsche Dermatologische Gesellschaft (DDG)</li>
                <li>Deutsche Gesellschaft für Phlebologie</li>
                <li>Deutsche Gesellschaft für Lymphologie</li>
                <li>
                    Deutsche Gesellschaft für Dermatochirurgie und Ästhetik e.V.
                    (DGAD e.V.)
                </li>
                <li>
                    Gesellschaft für Ästhetische Chirurgie Deutschlang (GÄCD)
                </li>
                <li>Verband für operative Dermatologie (VOD)</li>
                <li>
                    Verband für konservative und operative Lymphologie (BVL)
                </li>
            </ul>
        </Layout>
    );
};

export default Mitgliedschaften;
